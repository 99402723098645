<template>
  <div>
    <banner-slides-list
      v-model="innerValue.items"
      :view="innerValue.view"
    />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { createEnumProp } from '@/utils/props'
import Banner, { views } from '@/models/Banner'
import BannerSlidesList from './BannerSlidesList.vue'

export default {
  components: {
    BannerSlidesList
  },
  props: {
    view: createEnumProp({
      required: true,
      values: views
    }),
    random: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      innerValue: new Banner(this.$props)
    }
  },
  watch: {
    $props:{
      deep: true,
      handler ($props) {
        const banner = new Banner($props)
        if (isEqual(banner, $props)) return
        this.innerValue = banner
      }
    },
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  }
}
</script>
