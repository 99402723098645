import BaseService from './baseService'
import api from '../utils/api'

export class BannerItem {
  constructor () {

  }
}

class BannerService extends BaseService {
  get () {
    return api.get('banners')
  }

  save (config) {
    return api.patch('banners', config)
  }
}

export default new BannerService()

