import { uuid } from "../utils/id"

export const PLATFORM_WEB = 'web'
export const PLATFORM_IOS = 'ios'
export const PLATFORM_ANDROID = 'android'

export const THEME_LIGHT = 'light'
export const THEME_DARK = 'dark'
export const themes = { THEME_DARK, THEME_LIGHT }

export default class BannerSlide {
  constructor (options) {
    const {
      id = uuid(),
      title = null,
      mobileTitle = {},
      text = null,
      url = null,
      label = null,
      published = false,
      custom =  false,
      theme = null,
      platforms = [PLATFORM_WEB],
      imageUrl = null,
      imageId = null,
      mobileImageUrl = null,
      mobileImageId = null,
      navigationPath = null,
    } = options || {}

    return {
      id,
      title,
      mobileTitle,
      text,
      url,
      label,
      published,
      custom,
      theme,
      platforms,
      imageUrl,
      imageId,
      mobileImageUrl,
      mobileImageId,
      navigationPath,
    }
  }
}
