<template>
  <div class="banner-slides-list">
    <list-editor-light
      :dialog-options="{ maxWidth: 768 }"
      :items="items"
      append-to="start"
      @input="(e) => $emit('input', e)"
    >
      <template v-slot="scope">
        <vue-draggable
          draggable=".banner-slides-list__item--normal" class="banner-slides-list__items"
          v-model="innerValue"
        >
          <template #header>
            <banner-slide-add
              @click="() => scope.create()"
              class="banner-slides-list__item banner-slides-list__item--add"
            />
          </template>
          <banner-slides-list-item
            v-for="item, idx in scope.list"
            :key="item.id"
            v-bind="item"
            @click="() => scope.edit(idx)"
            @remove="() => scope.remove(idx)"
            class="banner-slides-list__item banner-slides-list__item--normal"
          />
        </vue-draggable>
      </template>

      <template v-slot:dialog="{ model, input }">
        <slide :value="model" :view="view" @input="input" />
      </template>

    </list-editor-light>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
// import ListEditorProvider from '@/components/ListEditor/ListEditorProvider.vue'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import Slide from './BannerSlide.vue'
import { createEnumProp } from '@/utils/props'
import { views } from '@/models/Banner'
import BannerSlidesListItem from './BannerSlidesListItem.vue'
import BannerSlideAdd from './BannerSlideAdd.vue'
import VueDraggable from 'vuedraggable'

export default {
  components: {
    VueDraggable,
    BannerSlideAdd,
    BannerSlidesListItem,
    Slide,
    ListEditorLight,
    // ListEditorProvider,
  },
  model: {
    prop: 'items',
    event: 'input'
  },
  props: {
    view: createEnumProp({
      required: true,
      values: views
    }),
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      innerValue: this.items
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    },
    items: {
      deep: true,
      handler (items) {
        if (isEqual(this.innerValue, items)) return
        this.innerValue = items
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-slides-list__items {
  overflow-x: auto;
  display: flex;
  grid-gap: 12px;
  padding-right: 20px;
  padding-bottom: 20px;


  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 4px;

  }
}
.banner-slides-list__item {
  flex: 0 0 auto;
  display: flex;
}
.banner-slides-list__item--add {
  width: 50px;
}
.banner-slides-list__item--normal {
  width: 200px;
}
</style>
