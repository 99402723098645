<template>
  <div
    class="slide-item"
    :class="classList"
    @click="() => $emit('click')"
  >
    <div class="slide-item__top">
      <v-btn
        icon
        small
        color="danger"
        @click.stop="() => $emit('remove')"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="slide-item__image">
      <ratio-span :ratio="3/4" />
      <img
        :src="imageUrl" aspect-ratio="16/9"
        class="slide-item__image-img"
      />
    </div>

    <div class="slide-item__content">
      <locale-text :value="title" escape only-locale="ru" class="font-weight-bold" style="line-height: 1.25em" />
      <locale-text :value="text" escape only-locale="ru" class="mt-2" style="line-height: 1.25em; font-size: 0.8em" />

      <div class="slide-item__content-extra">
        <div class="slide-item__content-extra-item caption">
          <span>Ссылка: </span>
          <a :href="url" @click.stop target="_blank" class="text-muted">
            {{ url ? url : '—' }}
          </a>
        </div>
        <div class="slide-item__content-extra-item caption">
          <span>
            Метка:
          </span>
          <span>
            {{ label ? label : '—' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleText from '@/components/Locale/LocaleText.vue'
import { THEME_DARK, THEME_LIGHT, themes } from '@/models/BannerSlide'
import { createEnumProp } from '@/utils/props'
import RatioSpan from '@/components/elements/ratio/RatioSpan'

export default {
  components: {
    RatioSpan,
    LocaleText
  },
  props: {
    id: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String
    },
    mobileImageUrl: {
      type: String
    },
    title: {
      type: Object,
    },
    mobileTitle: {
      type: Object
    },
    text: {
      type: Object
    },
    url: {
      type: String
    },
    label: {
      type: String
    },
    published: {
      type: Boolean,
      default: false
    },
    theme: createEnumProp({
      values: themes
    }),
    platforms: {
      type: Array,
    },
  },
  computed: {
    classList () {
      return [
        this.published ? '' : 'slide-item--draft',
        `slide-item--theme-${this.theme}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
$themes: (
  dark: (
    background: #321652,
    color: #fff,
  ),
  light: (
    background: #fff,
    color: #000,
  )
);

.slide-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s box-shadow;
  box-shadow: 0 2px 2px rgba(#000, 0.3);
  &:hover {
    box-shadow: 0 2px 5px rgba(#000, 0.5), 0 5px 20px rgba(#000, 0.1);
  }
}
.slide-item--draft {
  opacity: 0.4;
}

@each $theme, $params in $themes {
  .slide-item--theme-#{$theme} {
    background: map-get($params, background);
    color: map-get($params, color);
  }
}
.slide-item__top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 4px;
  z-index: 1;
}

.slide-item__content {
  padding: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.slide-item__content-extra {
  padding-top: 20px;
  margin-top: auto;
  margin-bottom: 0;
}

.slide-item__image {
  position: relative;
}
.slide-item__image-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-item__content-extra-item {
  display: flex;
  grid-gap: 0.3em;
  & > * {
    flex: 0 1 auto;
    &:first-child {
      opacity: 0.55;
    }
    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
