<template>
  <component
    :is="tag"
    class="ratio-span"
    :style="styles"
  />
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    ratio: {
      type: [Number, Function],
      default: 1
    },
  },
  computed: {
    styles () {
      let paddingBottom
      if (typeof this.ratio === 'function') {
        paddingBottom = this.ratio()
      } else if (typeof this.ratio === 'number') {
        paddingBottom = this.ratio
      }
      paddingBottom = paddingBottom !== undefined ? (paddingBottom * 100) + '%' : null
      return {
        paddingBottom
      }
    },
  }
}
</script>

<style scoped>
.ratio-span {
  display: block;
}
</style>
