export const VIEW_ROW = 'row'
export const VIEW_GRID = 'grid'
export const views = { VIEW_ROW, VIEW_GRID }

export default class Banner {
  constructor (options) {
    const {
      view = VIEW_ROW,
      random = false,
      items = [],
    } = options || {}
    return {
      view,
      random,
      items
    }
  }
}
