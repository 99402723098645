<template>
  <v-row>
    <v-col>
      <v-switch
        v-model="form.published"
        :label="form.published ? 'Опубликован' : 'Скрыт'"
        dense
        hide-details
        class="mb-5"
      />

      <locale-field
        component="v-textarea"
        rows="1"
        auto-grow
        name="title"
        placeholder="Title"
        autofocus
        hide-details
        outlined
        label="Заголовок"
        dense
        v-model="form.title"
        class="mb-8"
      />

      <locale-field
        component="v-textarea"
        rows="1"
        auto-grow
        outlined
        dense
        label="Краткий текст"
        name="text"
        placeholder="Text"
        hide-details
        v-model="form.text"
      />
      <div class="caption mt-10">
        Используйте переносы строк вместо тегов <code>&lt;br/&gt;</code>
      </div>
    </v-col>
    <v-col cols="6">
      <image-select-new
        :src="form.imageUrl"
        @input="onImageSelect"
        :ratio="0.67"
        class="mb-4"
      />

      <validation-provider slim rules="required" name="url" v-slot="{ errors }">
        <v-text-field
          name="url"
          v-model="form.url"
          :error-messages="errors[0]"
          label="Url"
          hide-details
          outlined
          dense
        />
      </validation-provider>

      <validation-provider slim rules="required" name="label" v-slot="{ errors }">
        <v-text-field
          name="label"
          v-model="form.label"
          :error-messages="errors[0]"
          label="Метка для трекинга"
          hide-details
          outlined
          dense
          class="mt-4"
        />
      </validation-provider>

      <validation-provider slim rules="required" name="theme" v-slot="{ errors }">
        <v-select
          :items="themesList"
          item-text="title"
          item-value="name"
          name="theme"
          v-model="form.theme"
          :error-messages="errors[0]"
          label="Тема"
          hide-details
          dense
          outlined
          class="mt-4"
        >
          <template #item="{ item }">
            <div>
              {{ item.title }} — {{ item.text }}
            </div>
          </template>
        </v-select>
      </validation-provider>
      <div v-if="themeInfo && themeInfo.text" v-html="themeInfo.text" />

    </v-col>

  </v-row>
</template>

<script>
import isEqual from 'lodash/isEqual'
import BannerSlide, { themes, THEME_DARK, THEME_LIGHT } from '@/models/BannerSlide'
import { views, VIEW_ROW, VIEW_GRID } from '@/models/Banner'
import LocaleField from '@/components/Locale/LocaleField.vue'
import imageSelectNew from '@/components/elements/image-select-new.vue'
import { createEnumProp } from '@/utils/props'

const themesExtra = {
  [THEME_DARK]: {
    title: 'Темная',
    text: 'Темный фон, светлый текст'
  },
  [THEME_LIGHT]: {
    title: 'Светлая',
    text: 'Светлый фон, темный текст'
  }
}

function getThemeExtra (name) {
  const data = themesExtra[name]
  if (data) {
    return {
      ...data,
      name,
    }
  } else {
    console.warn(`${name} not found in themesExtra`)
  }
}

const ratioExtras = {
  [VIEW_ROW]: {
    mobile: '',
    desktop: '',
  },
  [VIEW_GRID]: {
    mobile: '',
    desktop: ''
  }
}

const themesList = Object
  .entries(themes)
  .map(([ _, name ]) => getThemeExtra(name))
  .filter(item => item)

export default {
  components: {
    LocaleField,
    imageSelectNew,
  },
  props: {
    value: {
      type: Object
    },
    view: createEnumProp({
      required: true,
      values: views,
    })
  },
  data () {
    return {
      themesList,
      form: new BannerSlide(this.value)
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    },
    value: {
      deep: true,
      handler (value) {
        const bannerSlide = new BannerSlide(value)
        if (isEqual(bannerSlide, this.form)) return
        this.form = bannerSlide
      }
    }
  },
  computed: {
    themeInfo () {
      return getThemeExtra(this.form.theme)
    }
  },
  methods: {
    onImageSelect (image) {
      if (!image) return
      this.form.imageUrl = image.url
      this.form.imageId = image.id
    },
    onMobileImageSelect (image) {
      if (!image) return
      this.form.mobileImageUrl = image.url
      this.form.mobileImageId = image.id
    }
  }
}
</script>
