<template>
  <div>
    <div v-if="banner.pending">
      Loading...
    </div>
    <error-display v-else-if="banner.error" :error="banner.error" />
    <template v-else-if="banner.data">
      <v-row>
        <v-col>
          <div class="text-h4">
            Banners
          </div>
        </v-col>
        <v-col class="col-auto">
          <v-checkbox
            v-model="banner.data.random"
            label="Случайный порядок"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="banner.data.view"
            name="view"
            label="Вид"
            :items="views"
            item-text="label"
            item-value="value"
            dense
            outlined
          />
        </v-col>
        <v-col class="col-auto">
          <v-btn
            @click="save.run()"
            color="success"
            :disabled="save.pending"
          >
            {{ save.pending ? 'Save...' : 'Save' }}
          </v-btn>
        </v-col>
      </v-row>
      <banner-slides-manager
        v-bind="banner.data"
        @input="(payload) => banner.data = payload"
        class="mt-4"
      />
    </template>
  </div>
</template>

<script>
import { createRequest } from '@/utils/requestsFactory'
import bannersService from '@/services/banner'
import BannerSlidesManager from '@/components/BannerSlide/BannerSlidesManager.vue'
import { views, VIEW_GRID, VIEW_ROW } from '@/models/Banner'

const viewExtras = {
  [VIEW_GRID]: {
    label: 'Сетка'
  },
  [VIEW_ROW]: {
    label: 'Строка'
  }
}

export default {
  components: {
    BannerSlidesManager,
  },
  data () {
    return {
      banner: createRequest(() => bannersService.get()),
      save: createRequest(async () => {
        await bannersService.save(this.banner.data)
        this.$notify.success({ title: "Сохранено" })
      }),
      views: Object.entries(views).map(([key, value]) => {
        const { label }  = viewExtras[value] || {}
        return {
          label,
          value
        }
      })
    }
  },
  mounted () {
    return this.banner.run()
  }
}
</script>
