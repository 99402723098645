var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-switch',{staticClass:"mb-5",attrs:{"label":_vm.form.published ? 'Опубликован' : 'Скрыт',"dense":"","hide-details":""},model:{value:(_vm.form.published),callback:function ($$v) {_vm.$set(_vm.form, "published", $$v)},expression:"form.published"}}),_c('locale-field',{staticClass:"mb-8",attrs:{"component":"v-textarea","rows":"1","auto-grow":"","name":"title","placeholder":"Title","autofocus":"","hide-details":"","outlined":"","label":"Заголовок","dense":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('locale-field',{attrs:{"component":"v-textarea","rows":"1","auto-grow":"","outlined":"","dense":"","label":"Краткий текст","name":"text","placeholder":"Text","hide-details":""},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}}),_c('div',{staticClass:"caption mt-10"},[_vm._v(" Используйте переносы строк вместо тегов "),_c('code',[_vm._v("<br/>")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('image-select-new',{staticClass:"mb-4",attrs:{"src":_vm.form.imageUrl,"ratio":0.67},on:{"input":_vm.onImageSelect}}),_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"url","error-messages":errors[0],"label":"Url","hide-details":"","outlined":"","dense":""},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"name":"label","error-messages":errors[0],"label":"Метка для трекинга","hide-details":"","outlined":"","dense":""},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"theme"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.themesList,"item-text":"title","item-value":"name","name":"theme","error-messages":errors[0],"label":"Тема","hide-details":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.title)+" — "+_vm._s(item.text)+" ")])]}}],null,true),model:{value:(_vm.form.theme),callback:function ($$v) {_vm.$set(_vm.form, "theme", $$v)},expression:"form.theme"}})]}}])}),(_vm.themeInfo && _vm.themeInfo.text)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.themeInfo.text)}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }